import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import Features from "./common/Features";

const FeaturesPage = () => {
  return (
    <>
      <Header color="#51558a" />
      <div style={{ marginTop: "70px" }}>
        <Features />
      </div>
      <Footer />
    </>
  );
};

export default FeaturesPage;
