import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import About from "./common/About";

const AboutPage = () => {
  return (
    <>
      <Header color="#51558a" />
      <div style={{ marginTop: "70px" }}>
        <About />
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
