import React, { useState, useMemo } from "react";
import Header from "./common/Header.jsx";
import Footer from "./common/Footer.jsx";
import projects from "./projectsdata.js";
import toast from "react-hot-toast";
import Pagination from "./common/Pagination.jsx";

// Function to shuffle an array
const shuffleArray = (array) => {
  let shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const Projects = () => {
  // Shuffle projects on component mount
  const shuffledProjects = useMemo(() => shuffleArray(projects), []);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const itemsPerPage = 10;
  const filteredProjects = shuffledProjects.filter((project) =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const totalPages = Math.ceil(filteredProjects.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentProjects = filteredProjects.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const getRandomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  // Initialize likes and liked state for each project
  const initialLikes = currentProjects.reduce((acc, _, index) => {
    acc[index] = getRandomInt(10, 100); // Initialize likes
    return acc;
  }, {});

  const initialLikedState = currentProjects.reduce((acc, _, index) => {
    acc[index] = false; // Initialize as not liked
    return acc;
  }, {});

  const [likes, setLikes] = useState(initialLikes);
  const [liked, setLiked] = useState(initialLikedState);

  const handleLikeToggle = (projectIndex) => {
    setLikes((prevLikes) => {
      const newLikes = { ...prevLikes };
      const isLiked = liked[projectIndex];
      if (isLiked) {
        newLikes[projectIndex] = Math.max(0, newLikes[projectIndex] - 1);
      } else {
        newLikes[projectIndex] = (newLikes[projectIndex] || 0) + 1;
      }
      return newLikes;
    });

    setLiked((prevLiked) => ({
      ...prevLiked,
      [projectIndex]: !prevLiked[projectIndex],
    }));
    liked[projectIndex]
      ? toast.error("Oops! Like removed", { duration: 10000 })
      : toast.success("Thanks for a Like", { duration: 10000 });
  };

  const goToProject = (link) => {
    window.open(link, "_blank", "noreferrer");
  };

  return (
    <>
      <Header color="#51558a" />
      <section className="section blog" id="blog" style={{ marginTop: "70px" }}>
        <div className="container">
          <h2 className="h2 section-title underline">Works & Projects</h2>
          {/* Search input field */}
          <div
            className="search-container"
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                maxWidth: "400px",
                width: "100%",
              }}
            >
              <ion-icon
                name="search-outline"
                style={{
                  padding: "10px",
                  color: "#888",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              ></ion-icon>
              <input
                type="text"
                placeholder="Search by project name..."
                value={searchTerm}
                onChange={(e) => {
                  setCurrentPage(1); // Reset to first page on search
                  setSearchTerm(e.target.value);
                }}
                style={{
                  padding: "10px",
                  border: "none",
                  borderRadius: "4px",
                  width: "100%",
                  fontSize: "16px",
                  outline: "none",
                }}
              />
            </div>
          </div>
          {filteredProjects.length === 0 ? (
            <p style={{ textAlign: "center", fontSize: "18px", color: "#888" }}>
              No Project found
            </p>
          ) : (
            <>
              <ul className="blog-list">
                {currentProjects.map((project, index) => (
                  <li key={index}>
                    <div className="blog-card">
                      <figure className="banner">
                        <a href={project.link} target="_blank" rel="noreferrer">
                          <img
                            src={project.image}
                            width="750"
                            height="350"
                            loading="lazy"
                            alt={project.name}
                            className="img-cover"
                          />
                        </a>
                      </figure>

                      <div className="content">
                        <h3 className="h3 title">
                          <a
                            href={project.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {project.name}
                          </a>
                        </h3>

                        <p className="text">{project.desc}</p>

                        <div className="meta">
                          <div className="publish-date">
                            <ion-icon name="time-outline"></ion-icon>
                            <time datetime={project.date}>{project.date}</time>
                          </div>

                          <button
                            className="comment"
                            aria-label="Like"
                            onClick={() => handleLikeToggle(index)}
                          >
                            <ion-icon
                              name={liked[index] ? "heart" : "heart-outline"}
                            ></ion-icon>
                            <data value={likes[index] || 0}>
                              {likes[index] || 0}
                            </data>
                          </button>

                          <button
                            className="share"
                            aria-label="Share"
                            onClick={() => goToProject(project.link)}
                          >
                            <ion-icon name="eye-outline"></ion-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>

              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Projects;
