import project1 from "../images/ninetyone.png";
import project2 from "../images/ninetyone2.png";
import project3 from "../images/baterry.png";
import project4 from "../images/cactus.png";
import project5 from "../images/christmas.png";
import project6 from "../images/headphones.png";
import project7 from "../images/signin-register.png";
import project8 from "../images/movies.png";
import project9 from "../images/plant.png";
import project10 from "../images/delivery.png";
import project11 from "../images/comfy.png";
import project12 from "../images/nonex.png";
import project13 from "../images/lenders.png";
import project14 from "../images/repairshop.png";
import project15 from "../images/sheyskitchen.png";
import project16 from "../images/airbnb.png";
import project17 from "../images/donations.png";
import project18 from "../images/logistics.png";
import project19 from "../images/clothing.png";
import project20 from "../images/brain-wave.png";
import project21 from "../images/real-estate.png";
import project22 from "../images/car-dealers.png";
import project23 from "../images/clement.png";
import project24 from "../images/learning.png";
import project25 from "../images/furniture.png";
import project26 from "../images/car-dealers-2.png";
import project27 from "../images/pafrim.png";
import project28 from "../images/hotel.png";
import project29 from "../images/job-hunt.png";
import project30 from "../images/healthcare.png";
import project31 from "../images/general-company.png";
import project32 from "../images/gym.png";
import project33 from "../images/simple-furniture.png";
import project34 from "../images/beauty.png";
import project35 from "../images/marketing.png";
import project36 from "../images/future-lenders.png";
import project37 from "../images/barber.png";
import project38 from "../images/dentist.png";
import project39 from "../images/prodcast.png";
import project40 from "../images/nfts.png";
import project41 from "../images/house-hant.png";
import project42 from "../images/crypto.png";
import project43 from "../images/mechanics2.png";
import project44 from "../images/resume.png";
import project45 from "../images/creatives.png";
import project46 from "../images/pets.png";
import project47 from "../images/sneakers-ecom.png";
import project48 from "../images/sneakers2.png";
import project49 from "../images/doctor.png";
import project50 from "../images/furnitue2.png";
import project51 from "../images/clothing-ecommerce.png";
import project52 from "../images/web-dev.png";
import project53 from "../images/education.png";
import project54 from "../images/3d-designs.png";
import project55 from "../images/books.png";
import project56 from "../images/pets-two.png";
import project57 from "../images/digital-products.png";
import project58 from "../images/fly.png";
import project59 from "../images/books-two.png";
import project60 from "../images/food.png";
import project61 from "../images/nike.png";
import project62 from "../images/koffee.png";
import project63 from "../images/bike.png";
import project64 from "../images/food-delivery.png";
import project65 from "../images/hommade.png";
import project66 from "../images/edu-search.png";
import project67 from "../images/designers.png";
import project68 from "../images/consultancy.png";
import project69 from "../images/pricing-page.png";
import project70 from "../images/tech-web.png";
import project71 from "../images/food-seller.png";
import project72 from "../images/landing-page.png";
import project73 from "../images/furniture-simple.png";
import project74 from "../images/holloween.png";
import project75 from "../images/bani-logistics.png";
import project76 from "../images/big-data.png";
import project77 from "../images/insurance.png";
import project78 from "../images/ecom-html.png";
import project79 from "../images/car-rentals.png";
import project80 from "../images/single-product.png";
import project81 from "../images/seo.png";
import project82 from "../images/hosting.png";
import project83 from "../images/start-up.png";
import project84 from "../images/financial advisor.png";
import project85 from "../images/video-marketing.png";
import project86 from "../images/organic-ecommerce.png";
import project87 from "../images/education-scolar.png";
import project88 from "../images/ai.png";
import project89 from "../images/salvian.png";
import project90 from "../images/hybridmotor.png";

const projects = [
  {
    image: project1,
    name: "Ninetyone - ecommerce website",
    desc: "Best Selling Multi Vendor ecommerce Website. Empower Your Business with the Top-Performing Multi-Vendor E-commerce Platform",
    date: "21 Dec 2020",
    link: "https://www.ninetyone.co.ke/",
  },
  {
    image: project2,
    name: "Ninetyone subsidiary Website",
    desc: "Single Vender E-commerce business, a subsidiary of ninetyone.co.ke.",
    date: "26 Dec 2020",
    link: "https://ninetyone.vercel.app/",
  },
  {
    image: project3,
    name: "Battery status app website",
    desc: "see what is your battery %",
    date: "28 Dec 2020",
    link: "https://battery-status-omega.vercel.app",
  },
  {
    image: project4,
    name: "Cactus plant website",
    desc: "ORNAMENTAL PLANTS FOR YOUR HOME",
    date: "31 Dec 2020",
    link: "https://cactus-mauve.vercel.app",
  },
  {
    image: project5,
    name: "Merry Christmas website",
    desc: "Merry Christmas and Happy New Year!",
    date: "31 Dec 2020",
    link: "https://christmas-swart.vercel.app/",
  },
  {
    image: project6,
    name: "HeadPhones website",
    desc: "With a comfortable and adaptable case so that you can store it whenever you want, and keep your durability forever.",
    date: "31 Jan 2021",
    link: "https://headphones-tau.vercel.app/",
  },
  {
    image: project7,
    name: "Login Register website",
    desc: "Awesome Login Register web",
    date: "1 Feb 2021",
    link: "https://login-register-resp.vercel.app/",
  },
  {
    image: project8,
    name: "Movies Website",
    desc: "Responsive movies website",
    date: "3 Feb 2021",
    link: "https://movies-web-nine.vercel.app/",
  },
  {
    image: project9,
    name: "Plants website",
    desc: "Plants will make your life better",
    date: "4 Feb 2021",
    link: "https://plants-gold.vercel.app/",
  },
  {
    image: project10,
    name: "Delivery website",
    desc: "Order Products Faster Easier",
    date: "10 Feb 2021",
    link: "https://delivery-livid.vercel.app/",
  },
  {
    image: project11,
    name: "Comfy Funitures - website",
    desc: "A single vendor funiture shop fully funvtional and implemented with the admin dashboard. User friendly and smart UI design.",
    date: "13 Feb 2021",
    link: "https://comfyfunitureshop.vercel.app/",
  },
  {
    image: project12,
    name: "Nonex Computers Website - Thika",
    desc: " With a commitment to seamless user experiences and cutting-edge technologies, I specialize in turning ideas into captivating realities.",
    date: "15 Feb 2021",
    link: "https://nonexcomputers.com/",
  },
  {
    image: project13,
    name: "Lenders Bay Credit website",
    desc: "This is a loan management stystem website with fully fuctional admin dashboard and a users page. It manages loans from the day of release to maturity.",
    date: "25 Feb 2021",
    link: "https://lendersbaycredit.com/",
  },
  {
    image: project14,
    name: "Repair Shop - website",
    desc: "  Best Computer Repair Service at your own site. One click Solution to all Computer Problems. Doorstep Service within 90 mins.",
    date: "28 Feb 2021",
    link: "https://repairservices.vercel.app/",
  },
  {
    image: project15,
    name: "Grocery Shop - website",
    desc: " Get ready for a taste sensation! Explore our delicious menu and treat yourself to culinary delights crafted just for you.",
    date: "28 Feb 2021",
    link: "https://sheyskitchen.vercel.app/",
  },
  {
    image: project16,
    name: "Air BnB website",
    desc: "New Hosts get one-tap access to specially trained Community Support agents who can help with everything from account issues to billing support.",
    date: "2 April 2021",
    link: "https://journey-cusine.vercel.app/",
  },
  {
    image: project17,
    name: "3 dolts donations website",
    desc: " Make a difference today: donate to MAB CORP and help improve lives.",
    date: "15 April 2021",
    link: "https://donations-black.vercel.app/",
  },
  {
    image: project18,
    name: "Logistics Business website",
    desc: "We are committed to offering safe, fast transport services adapted to the specific needs of each customer.",
    date: "25 April 2021",
    link: "https://joshualogisticstwo.vercel.app/",
  },
  {
    image: project19,
    name: "Clothing Business website",
    desc: "We are majoring in women party dress, skirts, tops, suit and jumpsuits for may years. 100% good quality guarantee at a reasonable price.",
    date: "30 April 2021",
    link: "https://clothing-business.vercel.app/",
  },
  {
    image: project20,
    name: "Brain Waves website",
    desc: "Explore the Possibilities of AI Chatting with Brainwave",
    date: "2 June 2021",
    link: "https://brainwave-lovat.vercel.app/",
  },
  {
    image: project21,
    name: "Real Estate website",
    desc: "Discover Most Suitable Property",
    date: "16 Aug 2021",
    link: "https://real-estate-amber-pi.vercel.app/",
  },
  {
    image: project22,
    name: "Car Dealers website",
    desc: "Most dynamic car Dealers website- super ui",
    date: "26 April 2022",
    link: "https://car-delars.vercel.app/",
  },
  {
    image: project23,
    name: "Personal Portifolio website",
    desc: "Welcome to Clement's Empire, a leading mechanical engineering firm dedicated to providing innovative solutions and exceptional service.",
    date: "11 May 2022",
    link: "https://clement-one.vercel.app",
  },
  {
    image: project24,
    name: "Education Learning website",
    desc: "The beautiful thing about learning is that nobody can take it away from you",
    date: "8 June 2022",
    link: "https://learning-flame-eight.vercel.app",
  },
  {
    image: project25,
    name: "Furniture Shop website",
    desc: "We have furniture you want to buy!",
    date: "27 June 2022",
    link: "https://furniture-smoky-alpha.vercel.app",
  },
  {
    image: project26,
    name: "Car Seller website",
    desc: "FIND YOUR CAR",
    date: "8 July 2022",
    link: "https://car-seller-resp.vercel.app",
  },
  {
    image: project27,
    name: "Land Agent website",
    desc: "Your satisfaction is our top priority. We actively seek your feedback to continuously improve and ensure a fair and transparent experience.",
    date: "11 July 2022",
    link: "https://pafrim.vercel.app",
  },
  {
    image: project28,
    name: "Hotel website",
    desc: "Make Yourself At Home In Our Hotel.",
    date: "16 July 2022",
    link: "https://hotel-three-weld.vercel.app/",
  },
  {
    image: project29,
    name: "Job Hunt website",
    desc: "Search, Apply & Get Your Dream Job.",
    date: "1 Aug 2022",
    link: "https://job-hunt-liart.vercel.app/",
  },
  {
    image: project30,
    name: "Healthcare website",
    desc: "Providing an Exceptional Patient Experience",
    date: "30 Aug 2022",
    link: "https://helth-care.vercel.app/",
  },
  {
    image: project31,
    name: "General Company website - Adex",
    desc: "Crafting project specific solutions with expertise.",
    date: "1 Sep 2022",
    link: "https://general-company.vercel.app/",
  },
  {
    image: project32,
    name: "Gym website",
    desc: "Work Hard To Get Better Life",
    date: "8 Sep 2022",
    link: "https://gym-nine-eta.vercel.app/",
  },
  {
    image: project33,
    name: "Simple Furniture - website",
    desc: "One Page Furniture Website",
    date: "22 Sep 2022",
    link: "https://simple-furniture.vercel.app/",
  },
  {
    image: project34,
    name: "Beaty Shop  website",
    desc: "Reveal The Beauty of Skin",
    date: "29 Sep 2022",
    link: "https://beaty-ten.vercel.app/",
  },
  {
    image: project35,
    name: "Marketing website",
    desc: "We are available for marketing",
    date: "3 Oct 2022",
    link: "https://marketing-virid.vercel.app/",
  },
  {
    image: project36,
    name: "Furture Lenders website",
    desc: "Make Chat Easy For Today's Digital Customers",
    date: "9 Oct 2022",
    link: "https://furture-lenders-bay.vercel.app/",
  },
  {
    image: project37,
    name: "Barber website",
    desc: "BARBERS & HAIR CUTTING",
    date: "19 Oct 2022",
    link: "https://barber-sand.vercel.app/",
  },
  {
    image: project38,
    name: "Dentist website",
    desc: "We Are Best Dental Service",
    date: "21 Oct 2022",
    link: "https://dentist-lime.vercel.app",
  },
  {
    image: project39,
    name: "Prodcast website",
    desc: "Prodcast responsive web",
    date: "29 Oct 2022",
    link: "https://prodcast.vercel.app",
  },
  {
    image: project40,
    name: "NFTs BID website",
    desc: "Discover, collect, and sell extraordinary NFTs",
    date: "5 Nov 2022",
    link: "https://nftc-delta.vercel.app",
  },
  {
    image: project41,
    name: "House Hant website",
    desc: "We will help you find your Wonderful home",
    date: "16 Feb 2023",
    link: "https://house-hant.vercel.app/",
  },
  {
    image: project42,
    name: "Crypo website",
    desc: "Buy & Sell Digital Assets In The Cryptex",
    date: "8 Aug 2023",
    link: "https://cypto-ivory.vercel.app",
  },
  {
    image: project43,
    name: "Mechanics2 website",
    desc: "AUTO MAINTENANCE & REPAIR SERVICE",
    date: "12 Dec 2023",
    link: "https://mechanics2.vercel.app",
  },
  {
    image: project44,
    name: "Sam's Portifolio website",
    desc: " My job is to build your website so that it is functional and user-friendly but at the same time attractive.",
    date: "31 Jan 2024",
    link: "https://sams-portifolio.vercel.app/",
  },
  {
    image: project45,
    name: "Creatives website",
    desc: "Creative Landing Page",
    date: "31 March 2024",
    link: "https://creatives-gray.vercel.app/",
  },
  {
    image: project46,
    name: "Pets website",
    desc: "We Care Your Pets",
    date: "11 May 2024",
    link: "https://pets-three-neon.vercel.app/",
  },
  {
    image: project47,
    name: "Sneakers Ecommerce website",
    desc: " Competently expedite alternative benefits whereas leading-edge catalysts for change. Globally leverage existing an expanded  array of leadership.",
    date: "12 May 2024",
    link: "https://sneakers-ecom-nine.vercel.app",
  },
  {
    image: project48,
    name: "Sneakers2 Ecommerce website",
    desc: "Competently expedite alternative benefits whereas leading-edge catalysts for change. Globally leverage existing an expanded array of leadership.",
    date: "13 May 2024",
    link: "https://snearks.vercel.app/",
  },
  {
    image: project49,
    name: "Doctor Website",
    desc: "Find Nearest Doctor. Experienced Workers",
    date: "14 May 2024",
    link: "https://doctor-two-chi.vercel.app/",
  },
  {
    image: project50,
    name: "Furniture Website",
    desc: "Make Your Home Modern Design.",
    date: "15 May 2024",
    link: "https://furniture-eight-alpha.vercel.app/",
  },
  {
    image: project51,
    name: "Clothing-ecommerce Website",
    desc: "Unrivalled Fashion House",
    date: "16 May 2024",
    link: "https://clothing-ecommerce-sage.vercel.app/",
  },
  {
    image: project52,
    name: "Web-dev Website",
    desc: "We Design Interfaces That Users Love",
    date: "17 May 2024",
    link: "https://web-dev-tau-coral.vercel.app",
  },
  {
    image: project53,
    name: "Education Website",
    desc: "The Best Program to Enroll for Exchange",
    date: "18 May 2024",
    link: "https://education-lyart.vercel.app/",
  },
  {
    image: project54,
    name: "3d-designs Website",
    desc: "Building Digital Product, Brand and Experience",
    date: "20 May 2024",
    link: "https://3d-designs.vercel.app/",
  },
  {
    image: project55,
    name: "books Website",
    desc: "Read More And Make Success The Result Of Perfection.",
    date: "23 May 2024",
    link: "https://books-one-flax.vercel.app/",
  },
  {
    image: project56,
    name: "Pets Website",
    desc: "High Quality Pet Food Sale up to 40% off today",
    date: "26 May 2024",
    link: "https://pets-two-rouge.vercel.app",
  },
  {
    image: project57,
    name: "Digital-Products Website",
    desc: "Digital product design agency Create live segments and target the right people for messages based on their behaviors.",
    date: "29 May 2024",
    link: "https://digital-products-five.vercel.app/",
  },
  {
    image: project58,
    name: "Fly Jet Website",
    desc: "LUXURY JET FLIGHTS",
    date: "1 June 2024",
    link: "https://fly-gamma-eight.vercel.app",
  },
  {
    image: project59,
    name: "Books Website",
    desc: "Get Your New Book Collections There are many variations of passages",
    date: "2 June 2024",
    link: "https://books-two-beta.vercel.app/",
  },
  {
    image: project60,
    name: "Foods Website",
    desc: " Eat Sleep And Supper delicious Burger in town! Food is any substance consumed to provide nutritional support for an organism.",
    date: "4 June 2024",
    link: "https://foods-sable.vercel.app",
  },
  {
    image: project61,
    name: "Nike-product description website",
    desc: "Fall Limited Edition Sneakers",
    date: "6 June 2024",
    link: "https://product-description-two.vercel.app",
  },
  {
    image: project62,
    name: "Coffee Shop website",
    desc: "Greate coffee, good vibes",
    date: "7 June 2024",
    link: "https://koffee-mauve.vercel.app/",
  },
  {
    image: project63,
    name: "Bike Rent website",
    desc: "Book Your New Eco-Friendly Ride.",
    date: "8 June 2024",
    link: "https://bike-rent-mu.vercel.app/",
  },
  {
    image: project64,
    name: "Food Delivery website",
    desc: "The Best Restaurants In Your Home",
    date: "5 July 2024",
    link: "https://food-delivery-gules-eight.vercel.app/",
  },
  {
    image: project65,
    name: "Home Made website",
    desc: "We Make Top Quality Handmade Products",
    date: "10 July 2024",
    link: "https://hommade.vercel.app/",
  },
  {
    image: project66,
    name: "Education website",
    desc: "Education Is About Academic Excellence - nice search bar",
    date: "13 July 2024",
    link: "https://edu-search.vercel.app/",
  },
  {
    image: project67,
    name: "Designer website",
    desc: "WE ARE CREATIVE DESIGN AGENCY - CREATIVE AGENCY",
    date: "15 July 2024",
    link: "https://designers-ecru.vercel.app/",
  },
  {
    image: project68,
    name: "Consultancy website",
    desc: "Business planning advisor & specialist",
    date: "15 July 2024",
    link: "https://consultancy-web.vercel.app/",
  },
  {
    image: project69,
    name: "Pricing Page website",
    desc: "Fancy Pricing Page",
    date: "17 July 2024",
    link: "https://pricing-page-blush.vercel.app/",
  },
  {
    image: project70,
    name: "Tech website ",
    desc: "Secure IT solutions for a more secure environment",
    date: "18 July 2024",
    link: "https://tech-web-rose-sigma.vercel.app/",
  },
  {
    image: project71,
    name: "Food website",
    desc: "We do not cook, we create your emotions!",
    date: "19 July 2024",
    link: "https://foods-saller.vercel.app/",
  },
  {
    image: project72,
    name: "Nxtjs - Landing Page website",
    desc: "Elevate Your Android Experience with CherishOS",
    date: "19 July 2024",
    link: "https://nextjs-landing-page-sage.vercel.app/",
  },
  {
    image: project73,
    name: "Furniture Shop website",
    desc: "We Help You Make Modern Interior",
    date: "19 July 2024",
    link: "https://furniture-simple.vercel.app/",
  },
  {
    image: project74,
    name: "Halloween website",
    desc: "Halloween website - bring back my caddy",
    date: "20 July 2024",
    link: "https://holloween.vercel.app/",
  },
  {
    image: project75,
    name: "Bani Logistices website",
    desc: "We're Global Logistics Partners",
    date: "20 July 2024",
    link: "https://www.banilogistics.co.ke",
  },
  {
    image: project76,
    name: "Big Data Analytics website",
    desc: "We Provide Big Data Analytics And Techniques",
    date: "20 July 2024",
    link: "https://big-data-chi.vercel.app/",
  },
  {
    image: project77,
    name: "Insurance Co website",
    desc: "Life insurance makes your life happier",
    date: "20 July 2024",
    link: "https://insurance-hazel.vercel.app/",
  },
  {
    image: project78,
    name: "Ecommerce Co website",
    desc: "Ecommerce shop in html",
    date: "24 July 2024",
    link: "https://html-ecommerce-five.vercel.app/",
  },
  {
    image: project79,
    name: "Car Rentals website",
    desc: "Get 15% off your rental Plan your trip now",
    date: "24 July 2024",
    link: "https://car-rentals-roan.vercel.app/",
  },
  {
    image: project80,
    name: "Single Product website",
    desc: "Hair Shampoo For Healthy Hair",
    date: "24 July 2024",
    link: "https://single-product-gold.vercel.app/",
  },
  {
    image: project81,
    name: "SEO website",
    desc: "All in one SEO tool need to grow your business rapidly",
    date: "25 July 2024",
    link: "https://seo-web-liard.vercel.app/",
  },
  {
    image: project82,
    name: "Hosting website",
    desc: "Shared Hosting",
    date: "25 July 2024",
    link: "https://hosting-ashen.vercel.app/",
  },
  {
    image: project83,
    name: "StartUp website",
    desc: "Creative Services For Brands Grow",
    date: "26 July 2024",
    link: "https://start-up-pied.vercel.app/",
  },
  {
    image: project84,
    name: "Financial Advisor website",
    desc: "True Financial Support For You",
    date: "27 July 2024",
    link: "https://financial-advisor-six.vercel.app",
  },
  {
    image: project85,
    name: "Video - Marketing website",
    desc: "The Design Thinking Superpowers. Tools tutorials, design and innovation experts, all in one place! The most intuitive way to imagine your next user experience.",
    date: "9 August 2024",
    link: "https://video-marketing-weld.vercel.app",
  },
  {
    image: project86,
    name: "Orgnic Ecommerce website",
    desc: "FRUIT FRESH Vegetable 100% Organic Free Pickup and Delivery Available",
    date: "9 August 2024",
    link: "https://organic-ecommerce-ashy.vercel.app",
  },
  {
    image: project87,
    name: "Education Scolar website",
    desc: "Online Learning helps you save the time and resources",
    date: "9 August 2024",
    link: "https://education-scolar.vercel.app",
  },
  {
    image: project88,
    name: "AI website",
    desc: "Artificial Intelligence for Your Business",
    date: "13 August 2024",
    link: "https://ai-iota-cyan.vercel.app",
  },
  {
    image: project89,
    name: "Salvian Energies Websites",
    desc: "Delivering top-quality petroleum with unmatched reliability to keep your operations running smoothly.",
    date: "17 August 2024",
    link: "https://www.salvianenergies.com",
  },
  {
    image: project90,
    name: "Hybrid Motors Solution",
    desc: "Auto Maintenance & Repair Service. Our mission to provide top-notch automotive repair and maintenance services with integrity and professionalism.",
    date: "30 September 2024",
    link: "https://hybridmotorssolutions.co.ke",
  },
];

export default projects;
