import React from "react";
import hero from "../../images/hero-banner.png";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
const Hero = () => {
  const navigate = useNavigate();
  const goToAbout = () => {
    navigate("/about");
    toast.success("See more about us");
  };
  return (
    <>
      {" "}
      <section class="hero" id="home">
        <div class="container">
          <div class="hero-content">
            <p class="hero-subtitle">We Are Website Developers From Kenya</p>

            <h2 class="h2 hero-title">We Design Interfaces That Users Love</h2>

            <p class="hero-text">
              We pour our hearts into every project, working tirelessly to
              deliver exceptional web solutions tailored to elevate your
              business. From immersive WebApps to captivating websites, we're
              here to turn your vision into reality.
            </p>

            <button onClick={() => goToAbout()} class="btn">
              Learn More
            </button>
          </div>

          <figure class="hero-banner">
            <img
              src={hero}
              width="694"
              height="529"
              loading="lazy"
              alt="hero-banner"
              class="w-100 banner-animation"
            />
          </figure>
        </div>
      </section>
    </>
  );
};

export default Hero;
