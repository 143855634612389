import React from "react";
import toast from "react-hot-toast";

const Pricing = () => {
  const handleWhatsaap = () => {
    const whatsappUrl = "https://wa.me/+254712012113";
    toast.success("Redirecting to whatsaap");
    window.open(whatsappUrl, "_blank", "noreferrer");
  };
  return (
    <>
      {" "}
      <div class="container pricing" id="pricing">
        <header>
          <h2 class="h2 section-title underline">Our Pricing</h2>
          <div class="switch-section">
            <h6 class="sub-title">ONLY One Time Payments</h6>
          </div>
        </header>

        <div class="grid-parent">
          <div class="card basic-card">
            <h4 class="card-title">Basic</h4>

            <h1 class="price special-price">
              <span>sh.</span>12,000
            </h1>
            <hr id="hr" />
            <h5 class="text">5-Page Website</h5>
            <hr />
            <h5 class="text">Basic Design Customization</h5>
            <hr />
            <h5 class="text">Contact Form Integration</h5>
            <hr />
            <h5 class="text">Standard Support</h5>
            <hr />
            <div>
              <button
                onClick={() => handleWhatsaap()}
                class="button btn"
                type="button"
                name="button"
              >
                SUBSCRIBE
              </button>
            </div>
          </div>

          <div class="card professional-card">
            <h4>Professional</h4>

            <h1 class="price">
              <span>sh.</span>25,000
            </h1>
            <hr />
            <h5>10-Page Website</h5>
            <hr />
            <h5>Contact Form Integration</h5>
            <hr />
            <h5>1 Year Of Hosting</h5>
            <hr />
            <h5 style={{ color: "#fff" }}> Premium Support</h5>
            <hr />
            <div>
              <button
                class="professional-button pro-btn"
                type="button"
                name="button"
                onClick={() => handleWhatsaap()}
              >
                SUBSCRIBE
              </button>
            </div>
          </div>
          <div class="card master-card">
            <h4 class="card-title">Master</h4>

            <h1 class="price">
              <span>sh.</span>56,000
            </h1>
            <hr id="hr" />
            <h5 class="text">Unlimited-Page Website</h5>
            <hr />
            <h5 class="text">Advanced Design Customization</h5>
            <hr />
            <h5 class="text">Custom Website Development</h5>
            <hr />
            <h5 class="text">Contact Form Integration</h5>
            <hr />
            <h5 class="text">E-Commerce Functionality</h5>
            <hr />
            <h5 class="text">SEO Optimization</h5>
            <hr />
            <h5 class="text">Custom Support Options</h5>
            <hr />
            <div>
              <button
                class="button btn"
                type="button"
                name="button"
                onClick={() => handleWhatsaap()}
              >
                SUBSCRIBE
              </button>
            </div>
          </div>
        </div>

        <footer>
          <div class="attribution"></div>
        </footer>
      </div>
    </>
  );
};

export default Pricing;
