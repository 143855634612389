import React from "react";

const Services = () => {
  return (
    <>
      <section className="section service" id="services">
        <div className="container">
          <h2 className="h2 section-title underline">Our Specialization</h2>

          <ul className="service-list">
            <li>
              <div className="service-card">
                <div className="card-icon">
                  <ion-icon name="telescope-outline"></ion-icon>
                </div>

                <h3 className="h3 title">Strategy & Research</h3>

                <p className="text">
                  We analyze market trends and customer needs to create
                  strategic plans that drive growth and competitive advantage.
                </p>

                <button className="card-btn" aria-label="Show More">
                  <ion-icon name="chevron-forward-outline"></ion-icon>
                </button>
              </div>
            </li>

            <li>
              <div className="service-card">
                <div className="card-icon">
                  <ion-icon name="desktop-outline"></ion-icon>
                </div>

                <h3 className="h3 title">Web Development</h3>

                <p className="text">
                  We build custom, scalable, and secure websites using the
                  latest technologies to enhance user experience.
                </p>

                <button className="card-btn" aria-label="Show More">
                  <ion-icon name="chevron-forward-outline"></ion-icon>
                </button>
              </div>
            </li>

            <li>
              <div className="service-card">
                <div className="card-icon">
                  <ion-icon name="globe-outline"></ion-icon>
                </div>

                <h3 className="h3 title">Web Solutions</h3>

                <p className="text">
                  We offer tailored web solutions, including custom applications
                  and e-commerce platforms, to meet your business needs.
                </p>

                <button className="card-btn" aria-label="Show More">
                  <ion-icon name="chevron-forward-outline"></ion-icon>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Services;
