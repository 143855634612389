import React from "react";
import about from "../../images/about-banner.png";

const About = () => {
  return (
    <>
      {" "}
      <section class="section about" id="about">
        <div class="container">
          <figure class="about-banner">
            <img
              src={about}
              width="700"
              height="532"
              loading="lazy"
              alt="about banner"
              class="w-100 banner-animation"
            />
          </figure>

          <div class="about-content">
            <h2 class="h2 section-title underline">Why Our Agency</h2>

            <p class="about-text">
              Calling Nairobi, Kenya our home, we are a team of Full-Stack
              Website developers , driven by our passion for coding excellence
              and innovation. With backgrounds in Information Technology from
              top Kenyan universities, we thrive on honing our skills and
              crafting stunning applications and websites.
            </p>

            <p class="about-text">
              Specializing in the MERN Stack, we pour our hearts into every
              project, working tirelessly to deliver exceptional web solutions
              tailored to elevate your business. From immersive WebApps to
              captivating websites, we're here to turn your vision into reality.
            </p>
            <p class="about-text">
              And yes, we have a soft spot for crafting Full-Stack clones that
              are as impressive as they are functional. Let's embark on this
              coding journey together and make your online presence truly
              remarkable.
            </p>
            <p class="about-text">email : info@threedolts.co.ke</p>
            <p class="about-text">phone : 0791 004 783</p>

            <ul class="stats-list">
              <li class="stats-card">
                <p class="h3 stats-title">75</p>

                <p class="stats-text">Satisfied Clients</p>
              </li>

              <li class="stats-card">
                <p class="h3 stats-title">86</p>

                <p class="stats-text">Project Lunched</p>
              </li>

              <li class="stats-card">
                <p class="h3 stats-title">7</p>

                <p class="stats-text">Years Completed</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
