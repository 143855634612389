import React, { useState } from "react";
import projects from "../homeprojectdata.js";
import toast from "react-hot-toast";

const Projects = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  const totalPages = Math.ceil(projects.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentProjects = projects.slice(startIndex, endIndex);

  const initialLikes = currentProjects.reduce((acc, project, index) => {
    acc[index] = project.likes;
    return acc;
  }, {});

  const initialLikedState = currentProjects.reduce((acc, _) => {
    acc[0] = false;
    return acc;
  }, {});

  const [likes, setLikes] = useState(initialLikes);
  const [liked, setLiked] = useState(initialLikedState);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleLikeToggle = (index) => {
    setLikes((prevLikes) => {
      const newLikes = { ...prevLikes };
      const isLiked = liked[index];
      if (isLiked) {
        newLikes[index] = Math.max(0, newLikes[index] - 1);
      } else {
        newLikes[index] = (newLikes[index] || 0) + 1;
      }
      return newLikes;
    });

    setLiked((prevLiked) => ({
      ...prevLiked,
      [index]: !prevLiked[index],
    }));

    liked[index]
      ? toast.error("Opps! Like removed", { duration: 10000 })
      : toast.success("Thanks for a Like", { duration: 10000 });
  };

  const goToProject = (link) => {
    window.open(link, "_blank", "noreferrer");
  };

  return (
    <>
      <section className="section blog" id="blog">
        <div className="container">
          <h2 className="h2 section-title underline">Our works & Projects</h2>

          <ul className="blog-list">
            {currentProjects.map((project, index) => (
              <li key={index}>
                <div className="blog-card">
                  <figure className="banner">
                    <a href={project.link} target="_blank" rel="noreferrer">
                      <img
                        src={project.image}
                        width="750"
                        height="350"
                        loading="lazy"
                        alt={project.name}
                        className="img-cover"
                      />
                    </a>
                  </figure>

                  <div className="content">
                    <h3 className="h3 title">
                      <a href={project.link} target="_blank" rel="noreferrer">
                        {project.name}
                      </a>
                    </h3>

                    <p className="text">{project.desc}</p>

                    <div className="meta">
                      <div className="publish-date">
                        <ion-icon name="time-outline"></ion-icon>
                        <time datetime={project.date}>{project.date}</time>
                      </div>

                      <button
                        className="comment"
                        aria-label="Like"
                        onClick={() => handleLikeToggle(index)}
                      >
                        <ion-icon
                          name={liked[index] ? "heart" : "heart-outline"}
                        ></ion-icon>

                        <data value={likes[index] || 0}>
                          {likes[index] || 0}
                        </data>
                      </button>

                      <button
                        className="share"
                        aria-label="Share"
                        onClick={() => goToProject(project.link)}
                      >
                        <ion-icon name="eye-outline"></ion-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className="pagination">
            <button
              className="pagination-button"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Prev
            </button>

            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                className={`pagination-button ${
                  currentPage === index + 1 ? "active" : ""
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}

            <button
              className="pagination-button"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
