import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import Pricing from "./common/Pricing";

const PricingPage = () => {
  return (
    <>
      {" "}
      <Header color="#51558a" />
      <div style={{ marginTop: "70px" }}>
        <Pricing />
      </div>
      <Footer />
    </>
  );
};

export default PricingPage;
